import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

interface MedicationsListProps {
  existingItems: any[];
  proposedItems: any[];
}

const MedicationsList = ({
  existingItems,
  proposedItems,
}: MedicationsListProps) => {
  const [existingMedications, setExistingMedications] = useState(existingItems);
  const [proposedMedications, setProposedMedications] = useState(proposedItems);

  const existingHandler = (items: any[]) => {
    setExistingMedications(items);
  };

  const proposedHandler = (items: any[]) => {
    setProposedMedications(items);
  };

  return (
    <div>
      <ListApprover
        existingItems={existingMedications}
        existingHandler={existingHandler}
        proposedItems={proposedMedications}
        proposedHandler={proposedHandler}
        title="Medications"
      />
    </div>
  );
};

export default MedicationsList;
