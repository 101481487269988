import React from "react";
import {
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useState } from "react";

import "./gantt.css";
import { ViewMode } from "gantt-task-react";
type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcherMui: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  const [whichView, setWhichView] = useState<string>("Year");
  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newWhichView: string
  ) => {
    setWhichView(newWhichView);
  };
  return (
    <div className="ViewContainer">
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={() => onViewListChange(!isChecked)}
            name="checked"
            color="primary"
          />
        }
        label="Show List"
        sx={{ ml: 1, mb: 2 }}
      />
      <ToggleButtonGroup
        color="primary"
        value={whichView}
        exclusive
        onChange={handleViewChange}
        size="small"
        sx={{
          mb: 2,
        }}
      >
        <ToggleButton
          value="Hour"
          onClick={() => onViewModeChange(ViewMode.Hour)}
        >
          Hour
        </ToggleButton>
        <ToggleButton
          value="QuarterDay"
          onClick={() => onViewModeChange(ViewMode.QuarterDay)}
        >
          Quarter of Day
        </ToggleButton>
        <ToggleButton
          value="HalfDay"
          onClick={() => onViewModeChange(ViewMode.HalfDay)}
        >
          Half of Day
        </ToggleButton>
        <ToggleButton
          value="Day"
          onClick={() => onViewModeChange(ViewMode.Day)}
        >
          Day
        </ToggleButton>
        <ToggleButton
          value="Week"
          onClick={() => onViewModeChange(ViewMode.Week)}
        >
          Week
        </ToggleButton>
        <ToggleButton
          value="Month"
          onClick={() => onViewModeChange(ViewMode.Month)}
        >
          Month
        </ToggleButton>
        <ToggleButton
          value="Year"
          onClick={() => onViewModeChange(ViewMode.Year)}
        >
          Year
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
