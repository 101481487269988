import React from "react";

import { Box, Grid, Link, Typography, Stack } from "@mui/material";
import styled from "@emotion/styled";

import ChronologyEventMarker from "./ChronologyEventMarker";

interface ChronologyEventProps {
  event: any;
  lineType: string;
}
const ChronologyCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "15px",
  flexGrow: 1,
  marginBottom: "15px",
}));

const DateHolder = styled(Box)(({ theme }) => ({
  width: "100px",
  paddingTop: "16px",

  flexShrink: 0,
}));

const ChronologyEvent = ({ event, lineType }: ChronologyEventProps) => {
  return (
    <React.Fragment>
      <Box>
        <Stack direction="row">
          <DateHolder>
            <Typography variant="h5" align="right">
              {event.event_date &&
                new Date(event.event_date).toLocaleDateString()}
              {!event.event_date && "No date"}
            </Typography>
            <Typography variant="body1" align="right">
              {event.event_date &&
                new Date(event.event_date).toLocaleTimeString()}
              {!event.event_date && "No time"}
            </Typography>
            <Typography
              variant="body1"
              color="GrayText"
              align="right"
              sx={{ textTransform: "uppercase" }}
            >
              {event.event_type && event.event_type}
            </Typography>
          </DateHolder>

          <ChronologyEventMarker lineType={lineType} />
          <ChronologyCard>
            <Grid container spacing={4} columns={12}>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5} xxl={4}>
                <Typography variant="h5" sx={{ textWrap: "balance" }}>
                  {event.event_title}
                </Typography>
                <Typography variant="body1">
                  <strong>{event.author && event.author}</strong>
                </Typography>
                <Typography
                  variant="body1"
                  color="GrayText"
                  sx={{ textTransform: "uppercase" }}
                >
                  {event.author_role && event.author_role}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <Typography variant="body1" sx={{ pt: "3px", pb: 3 }}>
                  {event.event_abstract && event.event_abstract}
                  {!event.event_abstract && "--"}
                </Typography>
                <Link
                  href={event.link_to_pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  underline="hover"
                >
                  VIEW SOURCE
                </Link>
              </Grid>
            </Grid>
          </ChronologyCard>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ChronologyEvent;
