import React from "react";

import ReactQuill from "react-quill";
import { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { remark } from "remark";
import rehypeDomParse from "rehype-dom-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import ThinlayerBox from "../../AiApproval/ThinlayerBox";
import Markdown from "react-markdown";
import { AiIcon } from "../../icons/TLSvgIcons";
import { usePatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutMutation } from "../../../api/jabsCentralApiPrivate";

import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],
];

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 50px;
    position: relative;
  }
`;

interface SummaryProps {
  summary: any;
}

const Summary = ({ summary }: SummaryProps) => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const [summaryPut] =
    usePatientPatientIdSummaryExecutivePutPatientPatientIdSummaryExecutivePutMutation();
  const [summaryText, setSummaryText] = useState("Summary not generatedy");
  const [approved, setApproved] = useState(false);
  var thisSummary =
    summary.accepted.executiveSummary.length > 0
      ? summary.accepted.executiveSummary[
          summary.accepted.executiveSummary.length - 1
        ].description
      : "Summary not generated";
  console.log("summaries: ", summary.accepted.executiveSummary);
  useEffect(() => {
    setSummaryText(thisSummary);
  }, [thisSummary]);

  var thisDraft =
    summary.draft.executiveSummary.length > 0
      ? summary.draft.executiveSummary[
          summary.draft.executiveSummary.length - 1
        ].description
      : "Draft Summary not generated";

  var thisDraftid =
    summary.draft.executiveSummary.length > 0
      ? summary.draft.executiveSummary[
          summary.draft.executiveSummary.length - 1
        ].id
      : "";

  useEffect(() => {
    if (thisDraft === "Draft Summary not generated") {
      setApproved(true);
    }
  }, [thisDraft]);

  console.log("Summary: ", summary);

  const renderedString = renderToString(<Markdown>{thisDraft}</Markdown>);
  const [value, setValue] = useState(renderedString);
  const [markdown, setMarkdown] = useState(thisDraft);

  function htmlToMarkdown(htmlText: string) {
    const file = remark()
      .use(rehypeDomParse)
      .use(rehypeRemark)
      .use(remarkStringify)
      .processSync(htmlText);

    return String(file);
  }

  function handleChange(value: string) {
    setValue(value);
    setMarkdown(htmlToMarkdown(value));
  }

  function handleApproveButton() {
    // console.log(markdown);
    const summaryData = {
      id: thisDraftid,
      description: markdown,
      day: 0,
      name: "",
    };
    console.log("Summary Data: ", summaryData);
    summaryPut({
      patientId: selectedPatient,
      patientExecutiveSummary: summaryData,
    });

    setApproved(true);
    setSummaryText(markdown);
  }

  function handleCancelButton() {
    setApproved(true);
    setValue(renderedString);
  }

  return (
    <div>
      <Typography mb={3} ml={2} variant="h4">
        Summary
      </Typography>
      <ThinlayerBox>
        <Markdown>{summaryText}</Markdown>
      </ThinlayerBox>

      <Collapse in={!approved}>
        <Box>
          <Stack
            direction="row"
            sx={{ alignItems: "center", mt: 6, ml: 2, mb: 1 }}
          >
            <AiIcon />
            <Typography ml={1} variant="h4">
              Proposed
            </Typography>
          </Stack>
          <QuillWrapper>
            <ReactQuill
              modules={{
                toolbar: toolbarOptions,
              }}
              theme="snow"
              value={value}
              onChange={handleChange}
              placeholder="Type something.."
            />
          </QuillWrapper>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Button sx={{ mr: 4 }} onClick={handleCancelButton}>
              CANCEL
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={handleApproveButton}
            >
              APPROVE
            </Button>
          </Stack>
        </Box>
      </Collapse>
    </div>
  );
};

export default Summary;
