import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import {
  usePatientPatientIdGetPatientPatientIdGetQuery,
  usePatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostMutation,
} from "../../../api/jabsCentralApiPrivate";
// import Loader from "../../Loader";
import { isFetchBaseQueryError } from "../../../utils/isFetchBaseQueryError";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import BasicDetails from "./BasicDetails";
import Summary from "./Summary";
import Spacer from "../../navbar/appBar/Spacer";
import { AiIcon } from "../../icons/TLSvgIcons";
import PatientDetailsLoading from "./PatientDetailsLoading";
import PatientDetailsPrompt from "./PatientDetailsPrompt";
import Conditions from "./Conditions";
import Allergies from "./Allergies";
import Medications from "./Medications";
import SimpleChronology from "./SimpleChronology";

const PatientBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: `1000`,
}));

const PatientDetails: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const [generateSummary] =
    usePatientPatientIdSummaryGeneratePostPatientPatientIdSummaryGeneratePostMutation();

  const handleGenerateSummary = () => {
    generateSummary({ patientId: selectedPatient });
    console.log("Generating summary for patient: ", selectedPatient);
  };

  const {
    data: patientDetails,
    error: patientDetailsError,
    isLoading: isPatientDetailsLoading,
    isFetching: isPatientDetailsFetching,
  } = usePatientPatientIdGetPatientPatientIdGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;
  if (isPatientDetailsLoading) {
    content = <PatientDetailsLoading />;
  } else if (isPatientDetailsFetching) {
    content = <PatientDetailsLoading />;
  } else if (patientDetailsError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientDetailsError)
          ? String(patientDetailsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientDetails) {
      content = <PatientDetailsPrompt />;
    } else {
      const thisPatient = {
        id: patientDetails.id,
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        dob: patientDetails.dob,
        age: patientDetails.age,
        location: patientDetails.location,
        height: patientDetails.height,
        weight: patientDetails.weight,
        gender: patientDetails.genderBiological,
      };
      // console.log(patientDetails);
      console.log(patientDetails.summary);
      console.log(patientDetails.id);
      content = (
        <Box sx={{ position: "relative" }}>
          <PatientBar position="sticky">
            <Toolbar>
              <Box sx={{ px: 4 }}>
                <Typography variant="h5">
                  {patientDetails.firstName} {patientDetails.lastName}
                </Typography>
                <Typography variant="body1">
                  NHS: {patientDetails.id}
                </Typography>
              </Box>
              <Box sx={{ px: 3 }}>
                <Typography variant="h5">
                  DOB:{" "}
                  {patientDetails.dob &&
                    new Date(patientDetails.dob).toLocaleDateString()}
                </Typography>

                <Typography variant="body1">
                  Location: {patientDetails.location}
                </Typography>
              </Box>
              <Spacer />
              <Box sx={{ mr: 3 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AiIcon />}
                  onClick={handleGenerateSummary}
                >
                  GENERATE SUMMARY
                </Button>{" "}
              </Box>
            </Toolbar>
          </PatientBar>
          <Box sx={{ py: 6, px: 6 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} lg={8}>
                <Summary summary={patientDetails.summary} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <BasicDetails patient={thisPatient} />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 6, mb: 6 }} />
            <Grid container spacing={6}>
              <Grid item xs={12} lg={4}>
                {patientDetails.conditions && (
                  <Conditions
                    id={patientDetails.id}
                    conditions={patientDetails.conditions}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                {patientDetails.allergies && (
                  <Allergies
                    id={patientDetails.id}
                    allergies={patientDetails.allergies}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                {patientDetails.medications && (
                  <Medications
                    id={patientDetails.id}
                    medications={patientDetails.medications}
                  />
                )}
              </Grid>
            </Grid>
            <Divider sx={{ mt: 6, mb: 6 }} />
            <Grid container spacing={6}>
              <Grid item xs={12} lg={12}>
                <SimpleChronology />
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }
  }
  return content;
};

export default PatientDetails;
