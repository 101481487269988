import React from "react";
import PatientDetails from "./PatientDetails";
// import FileUpload from "./FileUpload";
import SingleFileUploader from "./TestFileUpload";
import FileList from "./FileList";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { Box, Divider } from "@mui/material";
import styled from "@emotion/styled";
import PatientDetailsPrompt from "./PatientDetailsPrompt";

const UploaderContainer = styled(Box)`
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.024) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 6px;
  height: calc(100vh - 187px);
  min-height: 662px;
  overflow: auto;
`;

const PatientFileUploader: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  let content = null;
  if (selectedPatient === "null") {
    content = <PatientDetailsPrompt />;
  } else {
    content = (
      <React.Fragment>
        <PatientDetails />
        <Box sx={{ pt: 0, px: 6, pb: 6 }}>
          <Divider sx={{ mt: 8, mb: 2 }} />
          <SingleFileUploader />
          <FileList />
        </Box>
      </React.Fragment>
    );
  }
  return <UploaderContainer>{content}</UploaderContainer>;
};

export default PatientFileUploader;
