import { Task } from "./types/public-types";
import eventDataSekari from "../../mockData/eventDataSekari";
import eventDataSekariTest from "../../mockData/eventDataSekariTest";

export function initTasks() {
  const tasks: Task[] = eventDataSekari.map((event) => {
    var start = !event.event_date ? new Date() : new Date(event.event_date);
    var end = !event.event_end ? start : new Date(event.event_end);

    return {
      start: start,
      end: end,
      name: event.event_title,
      id: String(event.id),
      grouping: event.event_type,
      progress: 100,
      type: "task",
      author: event.author,
      author_role: event.author_role,
      event_abstract: event.event_abstract,
      displayOrder: event.id,
      link_to_pdf: event.link_to_pdf,
    };
  });

  return tasks;
}

export function initTasksTest() {
  const tasks: Task[] = eventDataSekariTest.map((event) => {
    var start = !event.event_date ? "" : event.event_date;
    var end = !event.event_end ? start : event.event_end;

    return {
      start: new Date(start),
      end: new Date(end),
      name: event.event_title,
      id: String(event.id),
      grouping: event.event_type,
      progress: 100,
      type: "task",
      author: event.author,
      author_role: event.author_role,
      event_abstract: event.event_abstract,
      displayOrder: event.id,
      link_to_pdf: event.link_to_pdf,
    };
  });

  return tasks;
}

export function getStartEndDateForProject(tasks: Task[], projectId: string) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}
