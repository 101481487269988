import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { DeleteForever } from "@mui/icons-material";
import { RootState } from "../../../redux/store";
import { setSelectedFileItem } from "../../../redux/slices/fileSlice";
import {
  usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery,
  useFilesFileIdDeleteFilesFileIdDeleteMutation,
} from "../../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../../utils/isFetchBaseQueryError";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { Box, Dialog, Typography } from "@mui/material";
// import DocViewer from "react-doc-viewer";
// import { Document } from "react-pdf";

export interface FileViewerProps {
  fileUrl: string;
  fileType: string;
  open: boolean;
  onClose: () => void;
}
function FileViewer({ fileUrl, fileType, open, onClose }: FileViewerProps) {
  const handleClose = () => {
    onClose();
  };
  let viewerContent = null;
  if (fileType.includes("image")) {
    viewerContent = (
      <img src={fileUrl} alt="File Viewer" onClick={() => handleClose()} />
    );
  } else if (fileType.includes("pdf")) {
    viewerContent = (
      <React.Fragment>
        <Box onClick={() => handleClose()}>
          <embed
            src={fileUrl}
            aria-label="pdf viewer"
            type="application/pdf"
          ></embed>
          <Typography variant="body1" sx={{ p: 4 }}>
            Click to close
          </Typography>
        </Box>
      </React.Fragment>
    );
  } else {
    handleClose();
  }

  return <Dialog open={open}>{viewerContent}</Dialog>;
}

const columns: GridColDef[] = [
  { field: "filename", headerName: "Name", flex: 1, minWidth: 150 },
  { field: "status", headerName: "Status", width: 120 },

  { field: "filetype", headerName: "Type", flex: 0.5, minWidth: 150 },
  {
    field: "updateDate",
    headerName: "Date Updated",
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      return new Date(params.value as string).toLocaleDateString();
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 60,
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      return <DeleteForever />;
    },
  },
];

const FileList: React.FC = () => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [presignedUrl, setpresignedUrl] = useState("");
  const [fileType, setFileType] = useState("");

  const handleClose = () => {
    setViewerOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const newFileUrl = useSelector(
    (state: RootState) => state.newFileUrl.newFileUrl
  );

  const [deleteFile] = useFilesFileIdDeleteFilesFileIdDeleteMutation();

  const {
    data: patientFiles,
    error: patientFilesError,
    isLoading: isPatientFilesLoading,
    refetch: refetchPatientFiles,
  } = usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery(
    {
      patientId: selectedPatient,
    },
    { pollingInterval: 30000 }
    // { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    refetchPatientFiles();
  }, [newFileUrl, refetchPatientFiles]);

  const handleCellClick: GridEventListener<"cellClick"> = async (params) => {
    dispatch(setSelectedFileItem(params.row.fileId));
    console.log("Cell clicked: ", params);
    if (params.field !== "delete") {
      if (params.row.filetype !== "application/dicom") {
        setViewerOpen(true);
        setpresignedUrl(params.row.presignedUrl);
        setFileType(params.row.filetype);
      } else {
        navigate(`/page/dicom-viewer`);
      }
    } else {
      const deleteResponse = await deleteFile({ fileId: params.row.fileId });
      console.log("Delete response: ", deleteResponse);
      refetchPatientFiles();
    }
  };

  let content = null;

  if (isPatientFilesLoading) {
    console.log("Loading patient files...");

    content = <Loader />;
  } else if (patientFilesError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientFilesError)
          ? String(patientFilesError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <DataGrid
          rows={patientFiles?.files || []}
          columns={columns}
          getRowId={(row) => row.fileId}
          autoHeight
          onCellClick={handleCellClick}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {content}
      <FileViewer
        fileUrl={presignedUrl}
        fileType={fileType}
        open={viewerOpen}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default FileList;
