import React from "react";
import { Circle } from "@mui/icons-material";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const LineHolder = styled(Box)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textAlign: "center",
  width: "55px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  paddingLeft: "5px",
}));

const LineHolderTop = styled(LineHolder)(({ theme }) => ({
  paddingTop: "16px",
}));

const LineBottom = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,

  flexGrow: 1,
  width: "5px",
}));
const LineTop = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,

  height: "16px",
  width: "5px",
  flexShrink: 0,
}));

interface ChronologyEventMarkerProps {
  lineType: string;
}
const ChronologyEventMarker = ({ lineType }: ChronologyEventMarkerProps) => {
  switch (lineType) {
    case "single":
      return (
        <LineHolderTop>
          <Circle />
        </LineHolderTop>
      );

    case "top":
      return (
        <LineHolderTop>
          <Circle />

          <LineBottom />
        </LineHolderTop>
      );

    case "bottom":
      return (
        <LineHolder>
          <LineTop />
          <Circle />
        </LineHolder>
      );
    default:
      return (
        <LineHolder>
          <LineTop />
          <Circle />

          <LineBottom />
        </LineHolder>
      );
  }
};

export default ChronologyEventMarker;
