import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

interface ConditionsListProps {
  existingItems: any[];
  proposedItems: any[];
}

const ConditionsList = ({
  existingItems,
  proposedItems,
}: ConditionsListProps) => {
  const [existingConditions, setExistingConditions] = useState(existingItems);
  const [proposedConditions, setProposedConditions] = useState(proposedItems);

  const existingHandler = (items: any[]) => {
    setExistingConditions(items);
  };

  const proposedHandler = (items: any[]) => {
    setProposedConditions(items);
  };

  return (
    <div>
      <ListApprover
        existingItems={existingConditions}
        existingHandler={existingHandler}
        proposedItems={proposedConditions}
        proposedHandler={proposedHandler}
        title="Conditions"
      />
    </div>
  );
};

export default ConditionsList;
