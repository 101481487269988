import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import styled from "@emotion/styled";

import Loader from "../../Loader";
import ChronologyEvent from "./ChronologyEvent";
// import eventData from "../../../mockData/eventData";

import { usePatientPatientIdObservationsGetPatientPatientIdObservationsGetQuery } from "../../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../../utils/isFetchBaseQueryError";

const ChronologyContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
}));

const SimpleChronology = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const {
    data: patientObservations,
    error: patientObservationsError,
    isLoading: isPatientObservationsLoading,
    isFetching: isPatientObservationsFetching,
  } = usePatientPatientIdObservationsGetPatientPatientIdObservationsGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientObservationsLoading || isPatientObservationsFetching) {
    content = <Loader />;
  } else if (patientObservationsError) {
    console.log("Error: ", patientObservationsError);

    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientObservationsError)
          ? JSON.stringify(patientObservationsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientObservations) {
      content = <Typography>No observations found</Typography>;
    } else {
      var timeline = [];
      if (patientObservations.observations) {
        for (const observation of patientObservations.observations) {
          if (observation.observationValues) {
            for (const observationValue of observation.observationValues) {
              timeline.push({
                title: observation.observationType,
                effectiveDateTime: observationValue.effectiveDateTime,
                description: observationValue.description,
                type: "observation",
              });
            }
          }
        }
      }

      content = (
        <React.Fragment>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <IconButton>
              <AddCircle />
            </IconButton>
            <Typography variant="h4" sx={{ flexGrow: 1 }}>
              Chronology
            </Typography>
          </Stack>
          <ChronologyContainer>
            {timeline
              .sort((a, b) => {
                if (!a.effectiveDateTime) {
                  return -1;
                }
                if (!b.effectiveDateTime) {
                  return 1;
                }
                if (a.effectiveDateTime < b.effectiveDateTime) {
                  return -1;
                }
                if (a.effectiveDateTime > b.effectiveDateTime) {
                  return 1;
                }
                return 0;
              })
              .map((event: any, index) => {
                var lineType = "middle";

                if (timeline.length === 1) {
                  lineType = "single";
                } else if (index === 0) {
                  lineType = "top";
                } else if (index === timeline.length - 1) {
                  lineType = "bottom";
                }

                return (
                  <>
                    <ChronologyEvent
                      key={index}
                      lineType={lineType}
                      event={event}
                    />
                  </>
                );
              })}
          </ChronologyContainer>
        </React.Fragment>
      );
    }
  }

  return content;
};

export default SimpleChronology;
