import React from "react";
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
} from "@mui/material";

import styled from "@emotion/styled";

import Spacer from "../../navbar/appBar/Spacer";

const PatientBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  // [theme.breakpoints.up("md")]: {
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  // },
}));

const PatientDetailsLoading: React.FC = () => {
  const listHeight = 80;
  return (
    <React.Fragment>
      <PatientBar position="static">
        <Toolbar>
          <Box sx={{ px: 4 }}>
            <Skeleton
              variant="text"
              width={120}
              sx={{ backgroundColor: "#ffffff22" }}
            />
            <Skeleton
              variant="text"
              width={120}
              sx={{ backgroundColor: "#ffffff22" }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="text"
              width={150}
              sx={{ backgroundColor: "#ffffff22" }}
            />
            <Skeleton
              variant="text"
              width={150}
              sx={{ backgroundColor: "#ffffff22" }}
            />
          </Box>
          <Spacer />
          <Box sx={{ px: 5 }}>
            <CircularProgress color="secondary" size={30} />
          </Box>
        </Toolbar>
      </PatientBar>
      <Box sx={{ py: 6, px: 6 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <Skeleton variant="text" width={200} sx={{ fontSize: 18, mb: 2 }} />
            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Skeleton
              variant="text"
              width={200}
              sx={{ fontSize: 18, ml: 2, mb: 2 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 6, mb: 6 }} />
        <Grid container spacing={6}>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>

            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>

            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>

            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default PatientDetailsLoading;
