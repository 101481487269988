import React from "react";
// import { useState } from "react";
// import { useTheme } from "@mui/material/styles";
import { Timeline } from "../../types/extraction";
import { Box, Typography } from "@mui/material";
// import { ViewMode } from "gantt-task-react";
// import { Task } from "./types/public-types";

interface HorizontalTimelineProps {
  timeline: Timeline;
}

const HorizontalTimeline = ({ timeline }: HorizontalTimelineProps) => {
  console.log("timeline: ", timeline);
  // const theme = useTheme();
  // const [view, setView] = useState<ViewMode>(ViewMode.Year);
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3, ml: 3 }}>
        Timeline
      </Typography>
      <Box>
        {timeline.map((event: any, index) => {
          return (
            <Box key={index}>
              <Typography variant="h6">{event.event_date}</Typography>
              <Typography variant="body1">{event.event_abstract}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default HorizontalTimeline;
