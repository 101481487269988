import React from "react";

import { Box, Grid, Typography, Stack } from "@mui/material";
import styled from "@emotion/styled";

import ChronologyEventMarker from "./ChronologyEventMarker";

interface ChronologyEventProps {
  event: any;
  lineType: string;
}
const ChronologyCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "15px",
  flexGrow: 1,
  marginBottom: "20px",
  minHeight: "80px",
}));

const DateHolder = styled(Box)(({ theme }) => ({
  width: "120px",
  paddingTop: "15px",

  flexShrink: 0,
}));

const ChronologyEvent = ({ event, lineType }: ChronologyEventProps) => {
  return (
    <React.Fragment>
      <Stack direction="row">
        <DateHolder>
          <Typography variant="h5" align="right">
            {event.effectiveDateTime &&
              new Date(event.effectiveDateTime).toLocaleDateString()}
            {!event.effectiveDateTime && "No date"}
          </Typography>
          <Typography variant="body1" align="right">
            {event.effectiveDateTime &&
              new Date(event.effectiveDateTime).toLocaleTimeString()}
            {!event.effectiveDateTime && "No time"}
          </Typography>
        </DateHolder>

        <ChronologyEventMarker lineType={lineType} />
        <ChronologyCard>
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} xxl={4}>
              <Typography variant="h5" sx={{ textWrap: "balance" }}>
                {event.title}
              </Typography>
              <Typography
                variant="body1"
                color="GrayText"
                sx={{ textTransform: "uppercase" }}
              >
                {event.type && event.type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Typography variant="body1" sx={{ pt: "3px" }}>
                {event.description && event.description}
                {!event.description && "--"}
              </Typography>
            </Grid>
          </Grid>
        </ChronologyCard>
      </Stack>
    </React.Fragment>
  );
};

export default ChronologyEvent;
