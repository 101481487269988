import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNewFileUrl } from "../../../redux/slices/newFileUrlSlice";

import { RootState } from "../../../redux/store";
import {
  FilesPutFilesPutApiArg,
  useFilesPutFilesPutMutation,
} from "../../../api/jabsCentralApiPrivate";
import axios from "axios";
import { Button, Stack, Typography } from "@mui/material";
import { AttachFile, CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SingleFileUploader = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
    // handleUpload();
  };

  const doUpload = async (fileObject: any) => {
    if (fileObject.data) {
      const url = fileObject.data.presignedUrl;

      try {
        const response = await axios({
          method: "PUT",
          url: url,
          data: file,
          transformRequest: (d) => d,
        });
        console.log("Do upload response:", response);
        if (response.config.url) {
          dispatch(setNewFileUrl(response.config.url));
        }
        setFile(null);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No presigned URL found");
    }
  };

  const [mutate, { isLoading }] = useFilesPutFilesPutMutation();

  const handleUpload = async () => {
    if (file) {
      const fileInfo: FilesPutFilesPutApiArg = {
        file: {
          patientId: selectedPatient,
          filename: file.name,
          filetype: file.type,
          description: "test",
        },
      };
      try {
        const response = await mutate(fileInfo);
        console.log("Handle upload response:", response);
        console.log("isLoading: ", isLoading);

        await doUpload(response);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Documents
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<AttachFile />}
            sx={{ mr: 2 }}
          >
            {file && file.name}
            {!file && "Attach document"}
            <VisuallyHiddenInput onChange={handleFileChange} type="file" />
          </Button>
          {file && (
            <Button
              variant="contained"
              startIcon={<CloudUpload />}
              onClick={handleUpload}
            >
              Upload file
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};
export default SingleFileUploader;
