import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { jabsCentralApiPrivate } from "../api/jabsCentralApiPrivate";
import { jabsCentralApiPublic } from "../api/jabsCentralApiPublic";
import fileSelectionReducer from "./slices/fileSlice";
import patientSelectionReducer from "./slices/selectedPatientSlice";
import newFileUrlReducer from "./slices/newFileUrlSlice";

const store = configureStore({
  reducer: {
    [jabsCentralApiPrivate.reducerPath]: jabsCentralApiPrivate.reducer,
    [jabsCentralApiPublic.reducerPath]: jabsCentralApiPublic.reducer,
    fileSelection: fileSelectionReducer,
    patientSelection: patientSelectionReducer,
    newFileUrl: newFileUrlReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jabsCentralApiPrivate.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
