import React from "react";
import ListApprover from "./listApprover";
import { useState } from "react";

interface AllergiesListProps {
  existingItems: any[];
  proposedItems: any[];
}

const AllergiesList = ({
  existingItems,
  proposedItems,
}: AllergiesListProps) => {
  const [existingAllergies, setExistingAllergies] = useState(existingItems);
  const [proposedAllergies, setProposedAllergies] = useState(proposedItems);

  const existingHandler = (items: any[]) => {
    setExistingAllergies(items);
  };

  const proposedHandler = (items: any[]) => {
    setProposedAllergies(items);
  };

  return (
    <div>
      <ListApprover
        existingItems={existingAllergies}
        existingHandler={existingHandler}
        proposedItems={proposedAllergies}
        proposedHandler={proposedHandler}
        title="Allergies"
      />
    </div>
  );
};

export default AllergiesList;
