import React from "react";

import { Box, Typography } from "@mui/material";

import styled from "@emotion/styled";

import ChronologyEvent from "./ChronologyEvent";
// import HorizontalTimeline from "../gantt/HorizontalTimeline";

import { Timeline } from "../../types/extraction";

const ChronologyContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
}));

interface ChronologyProps {
  title: string;
  timeline: Timeline;
}

const Chronology = ({ title, timeline }: ChronologyProps) => {
  let content = null;

  content = (
    <React.Fragment>
      <Typography variant="h4" sx={{ mb: 3, ml: 3 }}>
        {title}
      </Typography>

      <ChronologyContainer>
        {timeline
          .sort((a, b) => {
            if (!a.event_date) {
              return -1;
            }
            if (!b.event_date) {
              return 1;
            }
            if (a.event_date < b.event_date) {
              return -1;
            }
            if (a.event_date > b.event_date) {
              return 1;
            }
            return 0;
          })
          .map((event: any, index) => {
            var lineType = "middle";

            if (timeline.length === 1) {
              lineType = "single";
            } else if (index === 0) {
              lineType = "top";
            } else if (index === timeline.length - 1) {
              lineType = "bottom";
            }

            return (
              <>
                <ChronologyEvent
                  key={index}
                  lineType={lineType}
                  event={event}
                />
              </>
            );
          })}
      </ChronologyContainer>
      {/* <HorizontalTimeline timeline={timeline} /> */}
    </React.Fragment>
  );

  return content;
};
export default Chronology;
