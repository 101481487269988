import React from "react";

import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { AddCircle } from "@mui/icons-material";

import { PatientConditions } from "../../../api/jabsCentralApiPrivate";
import ConditionsList from "./ConditionsList";
import { usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery } from "../../../api/jabsCentralApiPrivate";

const UploaderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "6px",
  padding: " 16px",
}));
const ExistingList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  margin: "0",
}));

interface ConditionsProps {
  conditions: PatientConditions[];
  id: string;
  // conditionsHandler: (items: any[]) => void;
}

const Conditions = ({ conditions, id }: ConditionsProps) => {
  const {
    data: updates,
    error: updatesError,
    isLoading: isUpdatesLoading,
    isFetching: isUpdatesFetching,
  } = usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery(
    {
      patientId: id,
    },
    { refetchOnMountOrArgChange: true }
  );
  let content = null;
  if (isUpdatesLoading || isUpdatesFetching) {
    content = (
      <Box>
        <Stack direction={"row"} alignItems="center">
          <IconButton disabled>
            <AddCircle />
          </IconButton>
          <Typography variant="h4">Conditions</Typography>
        </Stack>
        <UploaderContainer>
          <ExistingList dense>
            {conditions?.map((item, index) => (
              <ListItemButton key={index}>
                <ListItemText primary={item.description} />
              </ListItemButton>
            ))}
          </ExistingList>
          <Stack direction="row" sx={{ justifyContent: "center", mt: 6 }}>
            <CircularProgress color="secondary" />
          </Stack>
        </UploaderContainer>
      </Box>
    );
  } else if (updatesError) {
    console.log(updatesError);
    content = (
      <div>
        Error:{" "}
        {updatesError
          ? String(updatesError) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    let proposedItems = [] as any[];
    if (updates && updates.conditions) {
      proposedItems = updates.conditions.map(({ condition, updateId }) => ({
        item: condition,
        id: updateId,
      }));
    }

    content = (
      <ConditionsList
        existingItems={conditions || []}
        proposedItems={proposedItems}
      />
    );
  }

  return <Box>{content}</Box>;
};
export default Conditions;
