import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
// import PatientList from "../../components/admin/PatientList";
import PatientDrawer from "../../components/patientSelection/PatientDrawer";

import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import VitruvianSekaniAbara from "../../components/vitruvian/VitruvianSekaniAbara";
import Chronology from "../../components/chronology/Chronology";

import eventDataSekari from "../../mockData/eventDataSekari";
import eventDataSekariTest from "../../mockData/eventDataSekariTest";
import ThinlayerBox from "../../components/AiApproval/ThinlayerBox";
import RiskAssessment02 from "../../mockData/risk/RiskAssesment";
import GanttPanel from "../../components/gantt/GantPanel";
import HorizontalTimeline from "../../components/gantt/HorizontalTimeline";

const PageContent = styled(Box)`
  padding: 12px 24px 24px 24px;
`;

const LeftTools = () => {
  return (
    <Box sx={{}}>
      <Stack direction="row" spacing={6} alignItems="center">
        <Box></Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>LOCATION:</strong> Ward A / Bed 12
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>NHS:</strong> 012 345 6789
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>GENDER:</strong> Male
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>DOB:</strong> 05/03/1951 [72yrs]
          </Typography>

          <Typography variant="body1" color="InfoText"></Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="InfoText">
            <strong>WEIGHT:</strong> 108kg [BMI: 36]
          </Typography>
          <Typography variant="body1" color="InfoText">
            <strong>HEIGHT:</strong> 173cm
          </Typography>

          <Typography variant="body1" color="InfoText"></Typography>
        </Box>
      </Stack>
    </Box>
  );
};

function PatientViewMockPage() {
  return (
    <React.Fragment>
      <Helmet title="ABARA, Sekani" />
      <TLPagebar
        title="ABARA, Sekani"
        leftTools={<LeftTools />}
        breakpointLeft="md"
        rightTools={<PatientDrawer />}
      />
      <PageContent>
        <Card sx={{ py: 7, px: 7 }}>
          <Grid container spacing={6} columns={24}>
            <Grid
              item
              className="column-1"
              xs={24}
              sm={24}
              md={12}
              lg={10}
              xl={10}
              xxl={10}
            >
              <Grid container className="column-1-sub-grid" spacing={6}>
                <Grid
                  item
                  className="column-1-1"
                  xs={4}
                  sm={3}
                  md={4}
                  lg={4}
                  xl={3}
                  xxl={3}
                  sx={{ mt: 8 }}
                >
                  <VitruvianSekaniAbara />
                </Grid>

                <Grid
                  item
                  className="column-1-2"
                  xs={8}
                  sm={9}
                  md={8}
                  lg={8}
                  xl={9}
                  xxl={9}
                >
                  <Typography
                    variant="h4"
                    color="InfoText"
                    sx={{ mb: 3, ml: 2 }}
                  >
                    Conditions
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ ml: 0, mb: 6 }}
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Chip color="primary" label="Obesity" />
                    <Chip color="primary" label="Osteoarthritis" />
                    <Chip color="primary" label="Myocardial Infarction" />
                    <Chip color="primary" label="Stent-LAD Coranary Artery" />
                  </Stack>
                  <Typography
                    variant="h4"
                    color="InfoText"
                    sx={{ mb: 3, ml: 2 }}
                  >
                    Summary
                  </Typography>
                  <ThinlayerBox>
                    <Typography variant="body1" sx={{ pt: 2, px: 1, pb: 2 }}>
                      Mr. Abara is a 72-year-old, moderately obese (BMI = 36)
                      male of South African descent being considered for a Right
                      Total Hip Replacement secondary to osteoarthritis.
                      Patient’s noteworthy past medical history includes:
                      obesity, 50 pack-years of ongoing tobacco use, and a
                      non-ST-elevation myocardial infarction.
                    </Typography>
                    <Typography variant="body1" sx={{ pt: 2, px: 1, pb: 2 }}>
                      (NSTEMI) 5 years prior, resulting in a successful stenting
                      of his left anterior descending (LAD) coronary artery. He
                      has no cardiac symptoms, no shortness of breath with
                      ambulation or signs of congestive heart failure (no
                      peripheral oedema, pulmonary rales or orthopnea).
                    </Typography>
                  </ThinlayerBox>
                  <Stack
                    direction="row"
                    sx={{ flexWrap: "wrap", mb: 6 }}
                    useFlexGap
                    spacing={6}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h4"
                        color="InfoText"
                        sx={{ mt: 6, mb: 3, ml: 2 }}
                      >
                        Medications
                      </Typography>
                      <Stack spacing={2}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          label="Aspirin 75mg"
                        />
                        <Chip
                          variant="outlined"
                          color="primary"
                          label="Atorvastatin 40mg"
                        />
                        <Chip
                          variant="outlined"
                          color="primary"
                          label="Metoprolol 50mg"
                        />
                        <Chip
                          variant="outlined"
                          color="primary"
                          label="Ramipril 5mg"
                        />
                      </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h4"
                        color="InfoText"
                        sx={{ mt: 6, mb: 3, ml: 2 }}
                      >
                        Allergies
                      </Typography>
                      <Stack spacing={2}>
                        <Chip color="info" label="Penicillin" />
                      </Stack>
                    </Box>
                  </Stack>
                  <RiskAssessment02 />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className="column-2"
              xs={24}
              sm={24}
              md={12}
              lg={14}
              xl={14}
              xxl={14}
            >
              <Chronology
                title="Patient Chronology"
                timeline={eventDataSekari}
              />
              <HorizontalTimeline timeline={eventDataSekariTest} />

              <GanttPanel />
            </Grid>
          </Grid>
        </Card>
      </PageContent>
    </React.Fragment>
  );
}

export default PatientViewMockPage;
